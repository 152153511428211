<template>
  <div>
    <div class="info_contain" style="margin: 0 auto">
      <div class="edit_user">
        <el-button type="primary" @click="editUser()">修改</el-button>
        <el-button type="success" @click="saveUser()" v-if="isSave">保存</el-button>
      </div>
      <div class="flexaround">
        <div>
          <div style="margin: 10px 0">用户名:</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.name" :disabled="form.disabled" placeholder="请输入用户名"></el-input>
            </div>
          </div>
          <div style="margin: 10px 0">账号：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.username" placeholder="请输入帐号" :disabled="form.disabled"></el-input>
            </div>
          </div>
          <div style="margin: 10px 0">联系方式：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.telephone" placeholder="请输入联系方式" :disabled="form.disabled"></el-input>
            </div>
          </div>
          <div style="margin: 10px 0">邮箱：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.email" placeholder="请输入邮箱" :disabled="form.disabled"></el-input>
            </div>
          </div>
        </div>
        <div>
          <div style="margin: 10px 0">密码：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.password" type="password" @change="changePWD()"
                :disabled="form.disabled"></el-input>
            </div>
          </div>
          <div style="margin: 10px 0">性别：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-radio-group v-model="form.gender" :disabled="form.disabled">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div style="margin: 10px 0">生日：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-date-picker v-model="form.birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                :disabled="form.disabled">
              </el-date-picker>
            </div>
          </div>

          <!-- <div>账号权限：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px">
              <el-input v-model="form.roles[0]" disabled></el-input>
            </div>
          </div> -->
        </div>
        <!-- <div>
          <div style="width: 230px; height: 230px">
            <el-avatar
              shape="square"
              v-if="form.avatar"
              :size="230"
              :fit="fit"
              :src="form.avatar"
            ></el-avatar>
            <el-avatar
              v-else
              shape="square"
              :size="230"
              :fit="fit"
              :src="avatar"
            ></el-avatar>
          </div>
          <div style="margin-top: 20px; text-align: center">
            <el-upload
              :data="{
                token: $store.state.token,
              }"
              :file-list="materImgList"
              :on-success="
                (response, file, fileList) =>
                  changeMaterImg(1, response, file, fileList)
              "
              :show-file-list="false"
              action="https://api.gbservice.cn/api/public/bupload"
              class="upload-demo"
            >
              <el-button
                type="primary"
                style="height: 40px"
                :disabled="form.disabled"
                >更换头像
              </el-button>
            </el-upload>
          </div>
        </div> -->
      </div>
      <div class="flexaround" style="
          border-top: 1px solid #dbe9ef;
          padding-top: 50px;
          margin-top: 20px;
          margin-left: 140px;
        ">
        <div>
          <div>企业名称：</div>
          <div class="centerstyle toinput" style="">
            <div style="width: 300px">
              <el-input v-model="form.company" disabled></el-input>
            </div>
          </div>
        </div>
        <div class="">
          <div style="">企业地址：</div>
          <div class="centerstyle toinput">
            <div style="width: 300px" class="centerstyle">
              <el-input v-model="form.address" disabled></el-input>
            </div>
          </div>
        </div>
        <div style="width: 15%"></div>
      </div>
      <div>
        <div class=""></div>
      </div>
      <!-- <div class="" style="text-align: center; margin: 30px">
        <el-button
          type="primary"
          style="height: 40px; width: 100px"
          @click="updatzl()"
          >{{ form.disabled ? "编辑" : "取消" }}</el-button
        >
        <el-button
          type="primary"
          style="height: 40px; width: 100px"
          @click="updateUserInfo"
          :disabled="form.disabled"
          >保存</el-button
        >
      </div> -->
    </div>
  </div>
</template>
<script>
import { entInfo, updateUserInfo, userInfoEdit } from "../../../../api/user";
// getChildApi addChildApi,
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      fit: "contain",
      form: {
        id: "",
        name: "",
        openid: "",
        right_company: "",
        roles: "",
        telephone: "",
        token: "",
        username: "",
        gender: "",
        company: "",
        address: "",
        photo: "",
        disabled: true,
        book: "",
        password: ""
      },
      avatar:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      materImgList: [],
      page: 1,
      limit: 10,
      totalPage: 0,
      totalCount: 0,
      token: "",
      id: "",
      pwd: "******",
      isSave: false
    };
  },
  mounted() {
    this.entInfo();
  },
  methods: {
    // updatzl() {
    //   console.log("111");
    //   this.form.disabled = !this.form.disabled;
    // },
    changeMaterImg(index, response, file, fileList) {
      this.materImgList.push(response.data);
      this.form.avatar = response.data.url;
    },
    entInfo() {
      this.id = this.$store.state.user.id; //获取用户ID
      this.token = this.$store.state.token; //获取token
      // console.log(this.id )
      entInfo({ token: this.token }).then((res) => {
        this.$emit('getUserInfo', res.data)
        this.form = { ...res.data }
        this.form.disabled = true;
        console.log("form", this.form);
        console.log("res", res);
      });
    },
    updateUserInfo() {
      console.log("54357");
      this.form = {
        ...this.form,
        token: this.token,
        id: this.id,
      };
      if (this.materImgList && this.materImgList.length > 0) {
        this.form.avatar = this.materImgList[0].url;
      }
      if (this.pwd.length == 0 || this.pwd.split(" ").join("").length == 0) {
        this.$message({
          message: "请填写完整信息",
          type: "warning",
        });
        return;
      }
      this.$confirm("是否执行此操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateUserInfo(this.form).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.entInfo();
          });
        })
        .catch(() => { });
    },
    changePWD() {
      console.log(this.pwd);
      // this.form.password = this.pwd;
    },
    editUser() {
      this.isSave = true
      this.form.disabled = false
    },
    saveUser() {
      console.log(this.form.telephone, '打印密码')
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.form.telephone)) {
        // 手机号码不符合规则
        this.$message({
          type: "warning",
          message: "请输入有效的手机号码!",
        });
        return false
      }
      let data = {
        ...this.form,
        token: this.token,
        password: this.form.password == '' ? undefined : this.form.password
      }
      userInfoEdit(data).then((res) => {
        console.log(res);
        this.$message({
          type: "success",
          message: res.message,
        });
        this.entInfo();
        this.isSave = false
      });
    },
  },
};
</script>
<style scoped>
/deep/.el-avatar--square {
  border-radius: 20px;
}

/deep/.el-avatar>img {
  width: 100%;
}

/deep/.el-avatar {
  background-color: #ffffff;
  border: 1px solid #c0c4cc;
}

/deep/.el-upload {
  width: 100%;
}

/deep/.el-date-editor.el-input {
  width: 100%;
}

/* /deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
  width: 100%;
} */
/* /deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
} */
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

/deep/ .el-dialog__title {
  color: #ffffff;
}

/deep/ .el-dialog {
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}

.coianter {
  width: 100%;
  background-color: white;
  box-shadow: 3px 3px 7px 1px rgb(176 176 176 / 35%);
}

.title {
  padding: 40px;
  width: 120px;
  height: 60px;
  font-size: 24px;
  font-weight: bold;
}

.borderstyle {
  border: 1px solid;
}

.flexstyle {
  display: flex;
  flex-direction: row;
}

.flexaround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.centerstyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.xzbtn {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 0;
}

.bcbtn {
  width: 100%;
  /* text-align: bottom; */
  /* margin-top: 140px; */
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}

.toinput {
  margin-bottom: 30px;
  margin-top: 15px;
}

.info_contain .edit_user {
  display: flex;
  justify-content: flex-end;
}
</style>
