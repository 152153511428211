<template>
  <div class="coianter">
    <div class="tag_role"><el-tag type="success" size="mini">{{ userInfo == null ? '' : userInfo.roles[0] }}</el-tag></div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="个人资料" name="first">
        <exportuser @getUserInfo="getUserInfo" />
      </el-tab-pane>
      <!-- <el-tab-pane label="账号安全" name="second"><qiyeuser /></el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import exportuser from "./exportuser.vue";
import qiyeuser from "./qiyeuser.vue";
export default {
  components: { exportuser, qiyeuser },
  data() {
    return {
      activeName: "first",
      userInfo: null
    };
  },
  mounted() {

  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    getUserInfo(obj) {
      this.userInfo = obj
      // console.log('打印获取到的用户数据',obj)
    }
  },
};
</script>
<style scoped>
.coianter {
  background-color: #ffffff;
  padding: 27px 37px;
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 20px;
  position: relative;
}

.coianter .tag_role {
  position: absolute;
  left: 110px;
  top: 36px;
}</style>
